// wait for document ready state, before executing any scripts
if (document.readyState === 'complete') {
  init();
} else {
  document.addEventListener('DOMContentLoaded', () => {
    init();
  });
}

function init() {
  setupFilterHandler();
  setFilter();
  setupLoadMore();

  var permitCookieOverwrite = true;

  var $workerSelect = $('#worker');

  var currentWorkerCookie = getWorkerCookie();
  if (!isNaN(currentWorkerCookie)) {
    setWorker(currentWorkerCookie);
  }

  $workerSelect.on('change', function (e) {
    setWorker(getSelectedWorker());
  });

  $('#spinner').remove();

  function setWorker(pid) {
    if (pid == 0) {
      updateWorkerFields(0, 'Senden');
      unsetWorkerCookie();
    } else {
      // set cookie if needed
      if (currentWorkerCookie != pid) setWorkerCookie(pid);

      // set selected option in dropdown if needed
      if (getSelectedWorker() != pid) $workerSelect.val(pid);

      let workerName = $workerSelect.find(`option[value="${pid}"]`).text();

      // call update function
      updateWorkerFields(pid, `Senden als ${workerName}`);
    }
  }

  function getSelectedWorker() {
    return $workerSelect.find('option:selected').val();
  }

  function updateWorkerFields(val, text) {
    $('.bearbeiter-hidden').val(val);
    $('.comment-send').val(text);
  }

  function getWorkerCookie() {
    return getCookie($workerSelect.data('js-cookie'));
  }

  function setWorkerCookie(cvalue) {
    currentWorkerCookie = cvalue;
    setCookie($workerSelect.data('js-cookie'), cvalue, 365, '/bugtracker');
  }

  function unsetWorkerCookie() {
    setCookie($workerSelect.data('js-cookie'), '', 0, '/bugtracker');
  }

  function setFilter() {
    var hash = window.location.hash;
    var $personFilter = $('#person-filter');
    var $statusFilter = $('#status-filter');
    var $modusFilter = $('#modus-filter');

    if (hash === '') {
      // load preset (given from server)

      $personFilter.val($personFilter.data('js-preset'));
      $statusFilter.val($statusFilter.data('js-preset'));
      $modusFilter.val($modusFilter.data('js-preset'));
    } else {
      // unset filters, only set bug id filter

      var $idFilter = $('#id-filter');
      var bugId = '';
      var matches = hash.match(/(\d+)/);
      if (matches) bugId = matches[0];

      // prohibt cookie overwrite, otherwise they would be removed
      permitCookieOverwrite = false;
      $idFilter.val(bugId);
      $personFilter.val('0');
      $statusFilter.val('0');
      $modusFilter.val('0');
    }
  }

  function setupFilterHandler() {
    // handle filter changes & set cookies respectively
    $('#id-filter').on('change paste', function (e) {
      start_filter();
    });
    $('#person-filter, #status-filter, #modus-filter').on('change', function (e) {
      var value = e.target.value;
      var name = this.getAttribute('id').match(/\w+[^-]/)[0];
      setFilterCookie(e.target);
      start_filter();
    });

    // handle key input on ID filter to trigger filter function instantly
    var allowedKeys = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 8]; // 0-9 + backspace
    $('#id-filter').on('keyup', function (e) {
      if (allowedKeys.includes(e.keyCode)) {
        start_filter();
      }
    });

    // prevent sending the form with enter key
    $('#id-filter').on('keydown', function (e) {
      if (e.keyCode == 13) {
        e.preventDefault();
      }
    });

    // re-enable permition to set cookies by clicking on one of the filters
    $('#id-filter, #person-filter, #status-filter, #modus-filter').on('click', function () {
      permitCookieOverwrite = true;
    });
  }

  function setFilterCookie(target) {
    if (permitCookieOverwrite) {
      setCookie($(target).data('js-cookie'), target.value, 365, '/bugtracker');
    }
  }

  function start_filter() {
    $('.bug').show();

    $('[data-js-filter]').each(function (index, filter) {
      doFilter($(filter).data('jsFilter'), $(filter).val(), $(filter).data('jsFilterStart'));
    });

    updateBugCount();
  }

  function updateBugCount() {
    $('#eintraege-anzahl').text($('.bug').not(':hidden').length);
    $('#eintraege-geladen').text($('.bug').length);
  }

  function doFilter(attribute, value, startWith) {
    if (typeof value !== 'undefined' && value != 0) {
      var regexp = new RegExp(`^${value}`);
      var match, comparator;
      $('.bug').each(function (index, bug) {
        match = null;
        comparator = $(bug).data(attribute);
        if (comparator === null || comparator === '' || comparator == 0) comparator = 'empty';
        if (startWith === true && comparator !== 'empty' && value !== '') {
          match = comparator.toString().match(regexp);
        }
        if (comparator != value && match === null) {
          $(bug).hide();
        }
      });
    }
  }

  // get modal elements
  var $modal = $('#modal');
  var $modalTextarea = $('#T_Kommentar', $modal);
  var $modalDropdown = $('#modal-dropdown', $modal);

  // setup modal
  $modal.modal({
    backdrop: 'static',
    keyboard: false,
    show: false,
  });

  // focus textarea when modal is shown
  $modal.on('shown.bs.modal', function (event) {
    $modalTextarea.focus();
  });

  $modal.on('hidden.bs.modal', function () {
    // hide all footers & dropdown when modal is hidden
    $('.modal-footer:not(.hidden)').addClass('hidden');
    $modalDropdown.addClass('hidden');
    // reset textarea
    $modalTextarea.val('');
  });

  // setup listener for apply buttons of modal
  $('.modal-footer button:not([data-dismiss])', $modal).on('click', function (e) {
    e.preventDefault();
    var notification = {
      target: $(this).data('target'),
      type: $modalDropdown.hasClass('hidden') ? 'yes' : $('select', $modalDropdown).val(),
    };

    // save additional comment if given and confirm bug update
    if (!!$modalTextarea.val().trim()) {
      $modalForm = $('form', $modal);
      $.post($modalForm.attr('action'), $modalForm.serialize()).done(function (data) {
        updateCommentSection($.parseJSON(data));
        updateBug(notification);
      });
    } else {
      updateBug(notification);
    }
    $modal.modal('hide');
  });

  // setup listener for dismiss buttons of modal
  $('button[data-dismiss]', $modal).on('click', function (e) {
    e.preventDefault();
    resetBug();
    $modal.modal('hide');
  });

  function showModal(bugId, target, title, content, label, hint) {
    $(target, $modal).removeClass('hidden');
    setModalText('#modal-title', title);
    setModalText('#modal-content', content);
    if (label !== '') {
      showModalDropdown(label);
    }
    setModalText('#modal-hint', hint);
    setModalField('input[name="FKM_Eintrag"]', bugId);
    setModalField('input[name="FKM_Bearbeiter"]', getSelectedWorker());
    $modal.modal('show');
  }

  function setModalText(identifier, input) {
    $(identifier, $modal).text(input);
  }

  function setModalField(identifier, value) {
    $(identifier, $modal).val(value);
  }

  function showModalDropdown(labelText) {
    $('label', $modalDropdown).text(labelText);
    $modalDropdown.removeClass('hidden');
  }

  if ($('.flashversion')) {
    if (FlashDetect.installed) {
      $('input.flashversion').val(FlashDetect.major);
      $('p.flashversion').text(FlashDetect.major);
    }
  }

  var $bugUpdateForm = $('.bug-update-form');
  var $currCaller = null;
  if ($bugUpdateForm) {
    $('#bug-container').on('focus', '.bug-update-form select', function (event) {
      // update current caller
      $currCaller = $(this);
      // store old value in data attribute
      $currCaller.attr('data-old', $currCaller.val());
    });

    $('#bug-container').on('change', '.bug-update-form select', function (event) {
      event.preventDefault();
      // prepare variables
      var callerName = $currCaller.attr('name');
      var formData = [];
      $currCaller
        .parents('form')
        .serializeArray()
        .map(function (input) {
          formData[input.name] = input.value;
        });

      if (getSelectedWorker() != 0) {
        if (callerName == 'FKM_Bearbeiter' && formData.FKM_Bearbeiter != null && formData.FKM_Bearbeiter !== 'empty') {
          let workerName = $(`option[value="${formData.FKM_Bearbeiter}"]`, $currCaller).text();
          var text = `${workerName} wird über die Zuweisung per Mail informiert. Verfasse noch einen zusätzlichen Kommentar, damit das To-do klar wird.`;
          var hint = 'Der Kommentar wird zusätzlich im Bug-Verlauf gespeichert.';
          showModal(formData.FKM_Eintrag, '#modal-worker_change', 'Bearbeiter zuweisen', text, '', hint);
        } else if (callerName == 'FKM_Status' && formData.FKM_Status != null) {
          let creatorName = $(`#bug-${formData.FKM_Eintrag}`).find('[data-name="creator"] span').text();
          var label = `Soll ${creatorName} über die Statusänderung per Mail informiert werden?`;
          var hint = 'Der Kommentar wird auch ohne Mailversand im Bug-Verlauf gespeichert.';
          showModal(formData.FKM_Eintrag, '#modal-status_change', 'Statusänderung', '', label, hint);
        } else {
          updateBug();
        }
      } else {
        alert('Bitte wähle aus wer du bist.');
        resetBug();
      }
    });
  }

  function resetBug() {
    $currCaller.val($currCaller.attr('data-old'));
    $currCaller.attr('data-old', null);
  }

  function updateBug(notification) {
    let serializedForm = $currCaller.parents('form').serialize();
    var data = `${serializedForm}&Sender=${$currCaller.attr('name')}`;
    $.post($currCaller.parents('form').attr('action'), data).done(function (responseData) {
      var response = $.parseJSON(responseData);

      // update bug entry
      var $bug = $(`#bug-${response.PKM_Eintrag}`);
      $bug.data('bearbeiter', response.FKM_Bearbeiter);
      $bug.data('status', response.FKM_Status);
      $bug.data('modus', response.FKM_Modus);
      $bug.data('projekt', response.FKM_Projekt);
      $bug.data('prioritaet', response.FKM_Prioritaet);

      // show notification message
      showNotificaition(`Bug '${response.S_Betreff}' wurde aktualisert.`);

      // update list of bugs by re-filtering
      var filterAttribute = $currCaller.data('filter');
      if (filterAttribute != null) {
        doFilter(filterAttribute, $(`[data-js-filter="${filterAttribute}"]`).val());
        updateBugCount();
      }

      // remove 'old data' attribute from caller
      $currCaller.attr('data-old', null);

      if (notification) {
        sendBugChangeNotification(notification.target, notification.type);
      }
    });
  }

  function sendBugChangeNotification(target, type) {
    if (type === 'yes' || type === 'comments') {
      // prepare url
      var url = `${km_config.notify_url}/${target}`;
      if (type === 'comments') url += '/full';
      notifyByUrl(url, $('input[name="FKM_Eintrag"]', $modal).val());
    }
  }

  if ($('.bearbeiter-zuweisen-update')) {
    $('#bug-container').on('change submit', '.bearbeiter-zuweisen-update', function (event) {
      event.preventDefault();
      $.post($(this).attr('action'), $(this).serialize()).done(function (data) {});
    });
  }

  if ($('.new-comment')) {
    $('#bug-container').on('submit', '.new-comment', function (event) {
      event.preventDefault();
      var $input = $(this).find('textarea');
      var bugId = $(this).find('[name="FKM_Eintrag"]').val();
      if ($(this).find('.bearbeiter-hidden').val() != 0) {
        $.post($(this).attr('action'), $(this).serialize()).done(function (data) {
          $input.val('');
          updateCommentSection($.parseJSON(data));
          notifyByUrl(`${km_config.notify_url}/comment`, bugId);
        });
      } else {
        alert('Bitte wähle aus wer du bist.');
      }
    });
  }

  if ($('.bug')) start_filter(null);

  $('#erco-change-lang').click(function (e) {
    if ($('.erco-dropdown').hasClass('active')) {
      // hide dropdown
      $('.erco-dropdown').removeClass('active');
    } else {
      // show dropdown
      $('.erco-dropdown').addClass('active');
    }
  });

  function updateCommentSection(data) {
    var $div = $('<div></div>').addClass('comment').addClass('new');
    var $p_headline = $('<p></p>').html(`<em>${data.S_Vorname} ${data.S_Name} schrieb am ${data.TS_Erstellt}</em>`);
    var $p_text = $('<p></p>').text(data.T_Kommentar);
    $div.append($p_headline);

    $div.append($p_text);

    $(`#comments-area-${data.FKM_Eintrag}`).append($div);
    $('.new').animate({ backgroundColor: 'transparent' }, 500, function () {
      $(this).removeClass('new');
    });
  }

  function setCookie(cname, cvalue, exdays, pvalue) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var cookie = `${cname}=${cvalue};`;
    var expires = `expires=${d.toUTCString()};`;
    var path = `path=${pvalue}`;
    document.cookie = cookie + expires + path;
  }

  function getCookie(cname) {
    var name = `${cname}=`;
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        var cookieVal = c.substring(name.length, c.length);
        if (cookieVal !== false) return cookieVal;
      }
    }
    return '';
  }

  function setupLoadMore() {
    var $loadMoreBtn = $('#load-more');
    var loading = false;

    $loadMoreBtn.on('click', function () {
      if (loading) return;

      var $btn = $(this);
      var page = parseInt($btn.data('page'));
      var total = parseInt($btn.data('total'));
      var perPage = parseInt($btn.data('per-page'));
      var loadedCount = $('.bug').length;

      loading = true;
      $btn.prop('disabled', true).text('Lade...');

      $.get(window.location.pathname, {
        page: page,
      })
        .done(function (response) {
          $('#bug-container').append(response);

          start_filter();

          if (currentWorkerCookie && currentWorkerCookie != 0) {
            let workerName = $workerSelect.find(`option[value="${currentWorkerCookie}"]`).text();
            updateWorkerFields(currentWorkerCookie, `Senden als ${workerName}`);
          }

          if (loadedCount + perPage >= total) {
            $btn.parent().remove();
          } else {
            $btn
              .data('page', page + 1)
              .prop('disabled', false)
              .text('Ältere Einträge laden');
          }

          loading = false;
        })
        .fail(function () {
          $btn.prop('disabled', false).text('Fehler beim Laden. Erneut versuchen.');
          loading = false;
        });
    });
  }
}

/*
 *
 * Teile Ticket Bearbeiter/Ersteller mit
 *
 * @param Object Sender - Caller
 * @param Integer PKM_Eintrag - ID des Eintrages
 **/

function notify(Sender, PKM_Eintrag) {
  // var href = $(Sender).attr('href');

  notifyByUrl(Sender, PKM_Eintrag);
}

function notifyByUrl(Url, PKM_Eintrag) {
  $.post(Url, { PKM_Eintrag: PKM_Eintrag }).done(function (data) {
    if (data) {
      data = $.parseJSON(data);
      if (Array.isArray(data)) {
        var mails = data.map((person) => person.S_Email);
        showNotificaition(`E-Mail an ${mails.join(' und ')} versandt.`);
      } else {
        showNotificaition(`E-Mail an ${data.S_Email} versandt.`);
      }
    }
  });
}

function showNotificaition(text) {
  $.jGrowl(text, {
    closerTemplate: '<div>[ Alle schließen ]</div>',
    life: 10000,
  });
}
